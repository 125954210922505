// Import React
import ImageBlock from './ImageBlock';
import TextBlock from './TextBlock';
import VideoBlock from './VideoBlock';

export const components = {
  image_block: ImageBlock,
  text_block: TextBlock,
  video_block: VideoBlock
};
