// Import React
import React from 'react';

const ImageBlock = ({ slice }) => {
  return (
    <div>
      <div
        className="post-image full-width-image"
        style={{
          backgroundImage: `url(${slice.primary.post_image.url})`,
          height: '400px',
          backgroundSize: 'cover'
        }}
      >
        <div className="wrapper">
          {slice.primary.slice_labe && (
            <span className="image-label">{slice.primary.slice_labe}</span>
          )}
        </div>
      </div>
    </div>
  );
};

export default ImageBlock;
