// Import React
// slice zone prismic react
import { SliceZone } from '@prismicio/react';
// Import GraphQL
import { graphql } from 'gatsby';
// Import the GatsbySEO plugin
import { GatsbySeo } from 'gatsby-plugin-next-seo';
// Import the Gatsby Prismic plugin
import { withPreview } from 'gatsby-source-prismic';
import React from 'react';
import { Navbar, PageFooter } from '../components';
import { components } from '../Slices';
// Import our styles
import '../style/style.scss';

// Export PageQuery
export const query = graphql`
  query PostQuery($uid: String!) {
    prismicPost(uid: { eq: $uid }) {
      uid
      id
      data {
        post_append_site_title
        post_seo_description
        post_seo_robots
        post_seo_title
        post_title
        post_categories {
          post_category {
            uid
          }
        }
        body {
          ... on PrismicPostBodyTextBlock {
            id
            primary {
              post_text_content {
                raw
              }
            }
            slice_label
            slice_type
          }
          ... on PrismicPostBodyImageBlock {
            id
            slice_label
            slice_type
            primary {
              post_image {
                url
              }
            }
          }
          ... on PrismicPostBodyVideoBlock {
            id
            slice_label
            slice_type
            primary {
              post_video {
                raw
              }
            }
          }
        }
      }
    }
  }
`;

const Post = ({ data, pageContext }) => {
  if (!data) return null;

  const { settings, categoryUid } = pageContext;
  const page = data.prismicPost.data;

  return (
    <main className="page has-parent">
      <GatsbySeo title={page.post_seo_title} />
      <Navbar {...settings.data} />
      <section>
        <div className="container post-container">
          <div className="row">
            <div className="col-8 pe-5">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/">Home</a>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="/archive/news">News</a>
                  </li>
                  {categoryUid && (
                    <li className="breadcrumb-item">
                      <a href={`/archive/news/${categoryUid}`}>
                        {categoryUid.charAt(0).toUpperCase()}
                        {categoryUid.slice(1)}
                      </a>
                    </li>
                  )}
                  <li className="breadcrumb-item active" aria-current="page">
                    {page.post_title}
                  </li>
                </ol>
              </nav>

              <h1>{page.post_title}</h1>
              <SliceZone slices={page.body} components={components} />
            </div>
            <div className="col-4 bg-light p-4"></div>
          </div>
        </div>
      </section>
      <PageFooter />
    </main>
  );
};

export default withPreview(Post);
