// Import React
import React from 'react';

const VideoBlock = ({ slice }) => {
  return (
    <div>
      {slice.primary.post_video.raw.map((video, index) => (
        <div key={index}>
          <iframe
            src={video.text}
            frameBorder="0"
            width={'100%'}
            height={'600px'}
            allow="autoplay; encrypted-media"
            allowFullScreen
            title={index}
          />
        </div>
      ))}
    </div>
  );
};

export default VideoBlock;
