// Import React
import React from 'react';
import { PrismicRichText } from '@prismicio/react';

const TextBlock = ({ slice }) => {
  return (
    <div className="post-text">
      <PrismicRichText field={slice.primary.post_text_content?.raw} />
    </div>
  );
};

export default TextBlock;
